import { useEffect, useState } from "react";
import { Button, Divider, Header, HeaderSubheader, Icon, Label } from "semantic-ui-react";

import { FeedbackMessage } from "../../constants";
import { ErrorMessage, LoadingSpinner } from "../../components";
import { UserService, HelperService } from "../../services";

import css from './distributor-profile.module.css'
import { useNavigate } from "react-router-dom";

export function DistributorProfilePage() {

    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [profile, setProfile] = useState<any>({})
    const [userRole, setUserRole] = useState('')
    
    useEffect(() => {

         getUserProfile() 

         setUserRole(HelperService.getRoleFromToken())
    }, [])

    const getUserProfile = async () => {

        try {
            const data = await UserService.getProfile()

            setProfile(data)
    
        } catch (e) {
            console.log(e)

            setError(true)
        }

        setLoading(false)
    }

    const handleLogout = () => {
        
        HelperService.clearStorage()

        navigate('/')
    }

    return <div >

        <div className="flex-center">
            <Header as='h2' icon>
                <Icon name='user circle outline' />Paramètres du compte
                <HeaderSubheader>Retrouver ci-dessous les informations de votre compte</HeaderSubheader>
                <Label color='orange'>{userRole }</Label>
            </Header>
        </div>

        <br />

        {loading && !error && <LoadingSpinner />}

        {error && <ErrorMessage errorText={FeedbackMessage.PROFILE_FETCH_ERROR} />}

        {!loading && profile && !error &&
            <>

                <Header
                    dividing
                    as='h2'
                    content='Infos personnelles'
                    subheader='Ci-dessous les informations personnelles de votre compte'
                />
                <br/>
                
                <p className={css.profileInfoRow}>
                    <span>Identifiant</span>
                    <strong>#{profile.id}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Prénom</span>
                    <strong>{profile.firstname}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Nom de famille</span>
                    <strong>{profile.lastname}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Sexe</span>
                    <strong>{profile.sex}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Adresse</span>
                    <strong>{profile.address}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Email</span>
                    <strong>{profile.email}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Téléphone</span>
                    <strong>{HelperService.formatPhoneNumber(profile.phone)}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Numéro CNI</span>
                    <strong>{profile.nin}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Situation Matrimoniale</span>
                    <strong>{profile.marital_status}</strong>
                </p>

                <p className={css.profileInfoRow}>
                    <span>Salaire mensuelle</span>
                    <strong>{HelperService.formatAmount(profile.salary)} Fcfa</strong>
                </p>

                <br/>

                <Header
                    dividing
                    as='h2'
                    content='Infos professionnelles'
                    subheader='Ci-dessous les informations professionnelles de votre compte'
                />
                <br/>
                 <p className={css.profileInfoRow}>
                    <span>Société</span>
                    <strong>{profile.company.name}</strong>
                </p>
                 <p className={css.profileInfoRow}>
                    <span>Téléphone</span>
                    <strong>{HelperService.formatPhoneNumber(profile.company.phone)}</strong>
                </p>
                <p className={css.profileInfoRow}>
                    <span>Directeur</span>
                    <strong>{profile.company.chief_name}</strong>
                </p>
                <p className={css.profileInfoRow}>
                    <span>Enrôlement</span>
                    <strong>{HelperService.formatDateTime(new Date(profile.created_at))}</strong>
                </p>
        
                <br/>

            <div>
                <Divider />
                <Button color='orange' className="size-18" onClick={handleLogout}><Icon name="sign-out"/> Se déconnecter</Button> &nbsp;
            </div>
            <br/>
            <br/>
            </>
        }
    </div>
}