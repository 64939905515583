import { useState } from "react"

import { Outlet, useNavigate } from "react-router-dom"

import { Icon, Menu, MenuItem, MenuMenu } from "semantic-ui-react"

import { menuMappping } from "../../constants"

import { HelperService } from '../../services'


export function DistributorLayoutPage() {

    const navigate = useNavigate()
    const [companyName] = useState(HelperService.getCompanyName())
    const [activeItem, setActiveItem] = useState(menuMappping[HelperService.getCurrentPath()])


    const handleItemClick = (e: any, { name }: any) => {

        // if (name === 'Déconnexion') {
        //     HelperService.clearStorage()
        //     navigate('/')
        //     return
        // }

        setActiveItem(menuMappping[name.toLowerCase()])
        
        navigate(`/${companyName}/distributor/${menuMappping[name.toLowerCase()]}`)
    }

    return <>
        <div className="container">

            <Menu secondary color='orange' size='large'>
                <MenuItem
                    name='Accueil'
                    active={activeItem === ''}
                    onClick={handleItemClick}
                    icon='home'
                    color='orange'
                />

                <MenuItem
                    name='Réception'
                    active={activeItem === 'reception'}
                    onClick={handleItemClick}
                    key='reception'
                    icon='cart arrow down'
                    color='orange'
                />       

                <MenuItem
                    name='Demandes'
                    active={activeItem === 'provisionning'}
                    onClick={handleItemClick}
                    key='approvisionnement'
                    icon='dollar sign'
                    color='orange'
                />            
                <MenuItem
                    name='Partenaires'
                    active={activeItem === 'partners'}
                    onClick={handleItemClick}
                    key='partners'
                    icon='linkify'
                    color='orange'
                />

                <MenuMenu position='right'>
                    <MenuItem
                        name='profile'
                        active={activeItem === 'profile'}
                        onClick={handleItemClick}>
                        <Icon name='user circle outline' size="large"/>Mon compte
                    {/* <Dropdown item text='More'>
                        <DropdownMenu>
                            <DropdownItem icon='edit' text='Edit Profile' />
                            <DropdownItem icon='globe' text='Choose Language' />
                            <DropdownItem icon='settings' text='Account Settings' />
                        </DropdownMenu>
                    </Dropdown> */}
                    </MenuItem>
                </MenuMenu>
            </Menu>
            <br />
            <Outlet />
        </div>
    </>
}