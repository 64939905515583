import { createBrowserRouter } from "react-router-dom";

import { 
  LoginPage, 
  NotFoundPage, 
  CashierLayoutPage,
  CashierProfilePage, 
  CashierTillPage,


  AdminsysLayoutPage, 
  SupervisorLayoutPage,

  DistributorLayoutPage,
  DistributorDashboardPage,
  DistributorCashReceptionPage,
  DistributorProvisionningPage,
  DistributorPartnersPage,
  DistributorProfilePage,

  AdminsysDashboardPage,
  SupervisorDashboardPage,
  AreasPage,
  AgenciesPage,
  MobileMoneyPage,
  UsersPage,
  TillsPage,
  CompaniesPage,
  CompaniesProfilePage,
  CashierApproPage
} from "./pages";

export const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/admin_sys',
    element: <AdminsysLayoutPage />,
    children: [
      {
        path: '',
        element: <AdminsysDashboardPage />
      },
      {
        path: 'companies',
        element: <CompaniesPage />
      },
      {
        path: 'companies/:id',
        element: <CompaniesProfilePage />
      },
      {
        path: 'areas',
        element: <AreasPage/>,
      },
      {
        path: 'agencies',
        element: <AgenciesPage/>,
      },
      {
        path: 'services',
        element: <MobileMoneyPage/>,
      },
      {
        path: 'users',
        element: <UsersPage/>,
      },
      {
        path: 'tills',
        element: <TillsPage/>,
      },
      {
        path: 'partners',
        element: <DistributorPartnersPage/>
      },
      {
        path: 'reception',
        element: <DistributorCashReceptionPage/>
      },
      {
        path: 'provisionning',
        element: <DistributorProvisionningPage/>
      }
    ]
  },
  {
    path: '/:companyName/cashier',
    element: <CashierLayoutPage/>,
    children: [
      {
        path: 'profile',
        element: <CashierProfilePage/>
      },
      {
        path: 'request-for-appros',
        element: <CashierApproPage/>
      },
      {
        path: 'my-till',
        element: <CashierTillPage/>
      }
    ]
  },
  {
    path: '/:companyName/supervisor',
    element: <SupervisorLayoutPage />,
    children: [
      {
        path: '',
        element: <SupervisorDashboardPage/>
      },
      {
        path: 'agences',
        element: <AgenciesPage/>,
      },
      {
        path: 'users',
        element: <UsersPage/>,
      },
      {
        path: 'tills',
        element: <TillsPage/>,
      },
      {
        path: 'partners',
        element: <DistributorPartnersPage/>
      },
      {
        path: 'reception',
        element: <DistributorCashReceptionPage/>
      }
    ]
  },
  {
    path: '/:companyName/distributor',
    element: <DistributorLayoutPage />,
    children: [
      {
        path: '',
        element: <DistributorDashboardPage/>
      },
      {
        path: 'reception',
        element: <DistributorCashReceptionPage/>
      }, 
      {
        path: 'provisionning',
        element: <DistributorProvisionningPage/>
      },
      {
        path: 'partners',
        element: <DistributorPartnersPage/>
      },
      {
        path: 'profile',
        element: <DistributorProfilePage/>
      }
    ]
  },
  
]);