import { getAxiosInstance } from "../http"

import { Appro } from "../models"

export class CashierService {

    static createTransaction = async (data: any) => {}

    static getTransactionHistory = async () => {}

    static createRequestForAppro = async (id: string, status: string) => { }

    static getRequestsForAppro = async () => {
        
        const instance = getAxiosInstance()

        const response = await instance.get('cashiers/requests-involving-me')

        const result = response.data.map((item: any) => Appro.fromJSON(item))

        return result
    }

    static getDistributorsList = async () => {
        
        const instance = getAxiosInstance()

        const response = await instance.get('cashiers/distributors')

        return response.data
    }

    static getCashiersList = async () => {
        
        const instance = getAxiosInstance()

        const response = await instance.get('cashiers/nearbytills')

        return response.data
    }

    static sendRequestForAppro = async (data: any) => {
        
        const instance = getAxiosInstance()

        const response = await instance.post('cashiers/request-appros', JSON.stringify(data))

        const appro = Appro.fromJSON(response.data)

        console.log(appro)

        return appro
    }


    static acceptRequestForAppro = async (id: number) => {

        const instance = getAxiosInstance()

        const response = await instance.put(`cashiers/accept-request-appros/${id}`)

        const result = Appro.fromJSON(response.data)

        return result
    }

    static rejectRequestForAppro = async (id: number) => {

        const instance = getAxiosInstance()

        const response = await instance.put(`cashiers/reject-request-appros/${id}`)

        const result = Appro.fromJSON(response.data)

        return result
    }

    static confirmRequestAppro = async (id: number) => {

        const instance = getAxiosInstance()

        const response = await instance.put(`cashiers/confirmation/${id}`)

        const result = Appro.fromJSON(response.data)

        return result
    }
}