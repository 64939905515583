import { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";

import { Appro } from "../../models";
import { DsitributorService, HelperService } from "../../services";
import { DistributorApproForm, DistributorApproList, ErrorMessage, LoadingSpinner } from "../../components";
import { FeedbackMessage } from "../../constants";

export function DistributorProvisionningPage() {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userRole, setUserRole] = useState<string>('')
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [approsList, setApprosList] = useState<Appro[]>([])
    const [approsListCopy, setApprosListCopy] = useState<Appro[]>([])

    const addToList = (appro: Appro) => setApprosList([appro, ...approsList])

    const hideForm = () => setCreateModalVisible(false)


    useEffect(() => {

        setUserRole(HelperService.getRoleFromToken())

        DsitributorService.getRequestForApproList()
            .then(data => {
                setApprosList(data)
                setApprosListCopy(data)
                setLoading(false)
            })
            .catch(_ => setError(true))
            .finally(() => setLoading(false))
    }, [])

    return <div>

        {loading && <LoadingSpinner />}
        {error && <ErrorMessage errorText={FeedbackMessage.APPRO_FETCH_ERROR} />}

        {!loading && !error &&
            <>
                <div className='headerRow'>
                    <h2>Mes demandes d'appros:  <Label circular color="orange" size="big">{approsList.length}</Label></h2>
                </div>

                <div style={
                    {
                        height: createModalVisible ? '350px' : '0px',
                        overflow: 'hidden',
                        transition: 'height 0.5s ease-in-out',
                        marginBottom: createModalVisible ? '30px' : '0px'
                    }}>
                    <DistributorApproForm addToList={addToList} hideForm={hideForm} />
                </div>

                <DistributorApproList
                    userRole={userRole}
                    approsList={approsList}
                    setApprosList={setApprosList}
                    approsListCopy={approsListCopy}
                    setApprosListCopy={setApprosListCopy}
                />
            </>
        }
    </div>
}