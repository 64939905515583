import { getAxiosInstance } from "../http"

import { Appro } from "../models"

export class DsitributorService {

    static createAppro = async (data: any) => {
       
        const instance = getAxiosInstance()
        
        const response = await instance.post('distributors/appros', Appro.toJSON(data))

        const result = Appro.fromJSON(response.data)

        return result
    }

    static getRequestForApproList = async (): Promise<Appro[]> => {

        const instance = getAxiosInstance()

        const response = await instance.get('distributors/request-appros')

        const result = response.data.map((appro: any) => Appro.fromJSON(appro) as Appro)

        return result
    }

    static createRequestForAppro = async (id: string, status: string) => {

        const instance = getAxiosInstance()

        const response = await instance.put(`distributors/appros/${id}`, { status })

        const result = Appro.fromJSON(response.data)

        return result
    }

    static acceptRequestForAppro = async (id: number) => {

        const instance = getAxiosInstance()

        const response = await instance.put(`distributors/accept-request-appros/${id}`)

        const result = Appro.fromJSON(response.data)

        return result
    }

    static rejectRequestForAppro = async (id: number) => {

        const instance = getAxiosInstance()

        const response = await instance.put(`distributors/reject-request-appros/${id}`)

        const result = Appro.fromJSON(response.data)

        return result
    }
}