import { useEffect, useState } from "react"
import { Button, Icon } from "semantic-ui-react"

import { CashReception } from "../../models";
import { DistributorCashReceptionList, DistributorCashReceptionForm, LoadingSpinner, ErrorMessage } from "../../components"
import { CashReceptionService, HelperService } from "../../services"
import { FeedbackMessage } from "../../constants";

export function DistributorCashReceptionPage() {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userRole, setUserRole] = useState<string>('')
    const [cashReceptions, setCashReceptions] = useState<CashReception[]>([])
    const [createModalVisible, setCreateModalVisible] = useState(false)

    useEffect(() => {   
        
        setUserRole(HelperService.getRoleFromToken())

        CashReceptionService.getCashReceptionHistory()
            .then(data => setCashReceptions(data))
            .catch(err => setError(true))
            .finally(() => setLoading(false))
    }, [])

    const addToList = (cashReception: CashReception) => setCashReceptions([cashReception, ...cashReceptions])
    
    return <div>
        <div style={{width: '1200px', margin: 'auto'}}>

            <div className='headerRow'>
                <h2>Réception de cash</h2>
                {
                    userRole === 'Distributeur' &&
                    <Button color='orange' className='size-16' onClick={() => setCreateModalVisible(!createModalVisible)}>
                        { !createModalVisible && <Icon name="cart arrow down"/>}
                        {createModalVisible ? 'Annuler' : "Recevoir de l'argent"}
                    </Button>
                }
            </div>

            <div style={
                        {
                            height: createModalVisible ? '380px' : '0px', 
                            overflow: 'hidden',
                            transition: 'height 0.5s ease-in-out',
                            marginBottom: createModalVisible ? '30px' : '0px'
                    }}>
                <DistributorCashReceptionForm addToList={addToList} />
            </div>

            <br/>

            {loading && <LoadingSpinner />}
            { error && <ErrorMessage errorText={FeedbackMessage.CASH_RECEPTION_FETCH_ERROR} />}
            {!loading && !error &&  <DistributorCashReceptionList cashReceptions={cashReceptions} userRole={userRole} />}
           
        </div>
    </div>
}