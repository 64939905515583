
// color  6, back 0 - border 3
const css: {[k: string]: any} = {

    success: {
        color: '#008236',
        backgroundColor: '#dcfce7',
        border: '1px solid #a0d911',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '82px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    confirmed: {
        color: '#008236',
        backgroundColor: '#dcfce7',
        border: '1px solid #a0d911',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '82px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#f5222d',
        backgroundColor: '#fff1f0',
        border: '1px solid #ffa39e',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '82px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rejected: {
        color: '#f5222d',
        backgroundColor: '#fff1f0',
        border: '1px solid #ffa39e',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '82px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pending: {
        color: '#fa541c',
        backgroundColor: '#fff2e8',
        border: '1px solid #ffbb96',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '82px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    warning: {
        color: '#faad14',
        backgroundColor: '#fffbe6',
        border: '1px solid #ffc53d',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '82px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    info: {
        color: '#1890ff',
        backgroundColor: '#e6f7ff',
        border: '1px solid #91d5ff',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '82px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    accepted: {
        color: '#1890ff',
        backgroundColor: '#e6f7ff',
        border: '1px solid #91d5ff',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: '82px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}

export function Badge({ children, type }: { children: any, type: string }) {

    return <div style={css[type]}>{children}</div>
}