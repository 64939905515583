import { useEffect, useState } from "react";

import { Dimmer, DimmerDimmable, Loader, Segment, Image, Divider, Button } from "semantic-ui-react";

import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import css from './distributor-dashboard.module.css';
import { FeedbackMessage } from "../../constants";
import { DashboardService, HelperService } from "../../services";
import { DashCard, DistributorBalanceCard, ErrorMessage } from "../../components";


export function DistributorDashboardPage() {

    const [balances, setBalances] = useState([])
    const [omBalance, setOmBalance] = useState<any>({})
    const [cashBalance, setCashBalance] = useState<any>({})
    const [waveBalance, setWaveBalance] = useState<any>({})
    const [statistics, setStatistics] = useState<any>({})
    const [approPerDay, setApproPerDay] = useState<any>({})

    const [error, setError] = useState<any>({b: false, s: false, c: false})
    const [loadingStats, setLoadingStats] = useState<boolean>(true)
    const [loadingBalancies, setLoadingBalancies] = useState<boolean>(true)
    const [loadingApprosPerDay, setLoadingApprosPerDay] = useState<boolean>(true)

    useEffect(() => {

       setTimeout(() => {
        
                DashboardService.getDistributorBalances()
                .then(data => {
                    setBalances(data)
                    setOmBalance(HelperService.getBalanceByName(data, 'om'))
                    setCashBalance(HelperService.getBalanceByName(data, 'cash'))
                    setWaveBalance(HelperService.getBalanceByName(data, 'wave'))
                })
                .catch(_ => setError( (err: any) => ({...err, b: true})))
                .finally( () => setLoadingBalancies(false))       
                
                
            DashboardService.getDistributorApproStats()
                .then(data => setStatistics(data))
                .catch(_ => setError( (err: any) => ({...err, s: true})))
                .finally(() => setLoadingStats(false))

            DashboardService.getDistributorApproPerDay()
                .then(data => setApproPerDay(HelperService.buildDistLineChartDataset(data)))
                .catch(_ => setError( (err: any) => ({...err, c: true})))
                .finally(() => setLoadingApprosPerDay(false))

       }, 500);
    }, [])

    return <div>

        {error.b && <ErrorMessage errorText={FeedbackMessage.BALANCE_FETCH_ERROR} />}

        <DistributorBalanceCard balance={HelperService.computeTotalBalance(balances)} />

        <div className={css.cardRow}>

            <DashCard title="Solde Cash" 
                        subTitle={HelperService.getAnterorityInDays(cashBalance.last_modified_at)} 
                        balance={cashBalance.amount} 
                        loading={loadingBalancies} 
                        imageUrl="/images/porte-monnaie-2.png" />

            <DashCard title="Solde OM" 
                        subTitle={HelperService.getAnterorityInDays(omBalance?.last_modified_at)} 
                        balance={omBalance?.amount || 0} 
                        loading={loadingBalancies} 
                        imageUrl="/images/orangemoney.png" />

            <DashCard title="Solde Wave" 
                        subTitle={HelperService.getAnterorityInDays(waveBalance?.last_modified_at)}
                        balance={waveBalance?.amount || 0} 
                        loading={loadingBalancies} 
                        imageUrl="/images/wave-white-logo-7.png" 
                        imgWidth={58} imgHeight={50} />

            {/* <DashCard title="Solde OM" 
                        subTitle="15 jours" 
                        balance={120000} 
                        loading={loadingBalancies} 
                        imageUrl="/images/orangemoney.png" /> */}

            {/* <DashCard title="Solde Wave" subTitle="05 jours" balance={78000} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} /> */}
            {/* <DashCard title="Solde OM" subTitle="15 jours" balance={120000} imageUrl="/images/orangemoney.png" />
                <DashCard title="Solde Wave" subTitle="05 jours" balance={78000} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} />
                <DashCard title="Solde OM" subTitle="15 jours" balance={120000} imageUrl="/images/orangemoney.png" />
                <DashCard title="Solde Wave" subTitle="05 jours" balance={78000} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} />
                <DashCard title="Solde OM" subTitle="15 jours" balance={120000} imageUrl="/images/orangemoney.png" />
                <DashCard title="Solde Wave" subTitle="05 jours" balance={78000} imageUrl="/images/wave-white-logo-7.png" imgWidth={58} imgHeight={50} /> */}
        </div>

        <div className={css.statRow}>
            <div className={css.lineChart}>
            <h3 className="mb-30">Historique appros</h3>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart  width={500} height={300} data={approPerDay} margin={{right: 20, bottom: 10}}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="appro" stroke="#e55b00"  strokeWidth={2} activeDot={{ r: 8 }} />
                        {/* <Line type="monotone" dataKey="uv" stroke="#000000" strokeWidth={2} /> */}
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <div className={css.statistics}>
                <h2 className="mb-30">Statistiques</h2>
                {error.s && <ErrorMessage errorText={FeedbackMessage.GENERAL_ERROR}/>}

                {/* <div className={css.statisticsCard}>
                        <h3 className="flex space-between">5 235 <span>80%</span></h3>
                        <span className="size-16">approvisionnements validés</span>
                    </div>
                    <div className={css.statisticsCard}>
                        <h3 className="flex space-between">150  <span>4 %</span></h3>
                        <span className="size-16">approvisionnements en cours</span>
                    </div>
                    <div className={css.statisticsCard}>
                        <h3 className="flex space-between">1 235 <span>16 %</span></h3>
                        <span className="size-16">approvisionnements en conflit</span>
                    </div> */}

                {/* <div className={css.statisticsCard}>
                        <h3>5 235</h3>
                        <span className="size-16">approvisionnements validés</span>
                    </div>
                    <div className={css.statisticsCard}>
                        <h3>150</h3>
                        <span className="size-16">approvisionnements en cours</span>
                    </div>
                    <div className={css.statisticsCard}>
                        <h3>1 235</h3>
                        <span className="size-16">approvisionnements en conflit</span>
                    </div> */}

              {  
                !loadingStats && !error.s && <>
                        <div className={css.statisticsCard}>
                            <h3>{HelperService.padNumberLeft(statistics.confirmed, 2)}</h3>
                            <span className="size-16">Demande d'appros validées</span>
                            <h3>{statistics.confirmed_ratio % 100}%</h3>
                        </div>
                        <div className={css.statisticsCard}>
                            <h3>{HelperService.padNumberLeft(statistics.accepted, 2)}</h3>
                            <span className="size-16">Demande d'appros acceptées</span>
                            <h3>{statistics.accepted_ratio * 100}%</h3>
                        </div>
                        <div className={css.statisticsCard}>
                            <h3>{HelperService.padNumberLeft(statistics.rejected, 2)}</h3>
                            <span className="size-16">Demande d'appros rejetées</span>
                            <h3>{statistics.rejected_ratio * 100}%</h3>
                        </div> 
                        <Divider />
                        <br/>
                        <div className={css.statisticsCard}>
                            <h3 className="orange">{HelperService.padNumberLeft(statistics.pending, 2)}</h3>
                            <span className="size-16 orange text-bold">demandes en attente</span>
                            <h3 className="orange">{statistics.pending_ratio * 100}%</h3>
                        </div> 
                        </>
                }

                {
                    loadingStats &&
                        <DimmerDimmable as={Segment} dimmed={true}>
                            <Dimmer active={true} inverted>
                                <Loader><small>Chargement...</small></Loader>
                            </Dimmer>

                            <p><Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' /></p>
                            <p><Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' /></p>
                        </DimmerDimmable>
                }

                <div className="flex-center"><Button basic color='orange' className="text-bold">Plus d'infos</Button></div>
            </div>
        </div>
    </div>
}