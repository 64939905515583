import { useEffect, useState } from 'react'

import { Button, Divider, Dropdown, Icon, Input, Label, List, ListItem } from 'semantic-ui-react'

import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react'

import { Appro } from '../../models'
import { HelperService, CashierService } from '../../services'
import { FeedbackMessage } from '../../constants'
import { CashierApproCreateModalForm, Badge, NoData, LoadingSpinner, ErrorMessage } from '../../components'


import css from './cashier-appro.module.css'


const filterOptions = [
    { key: 1, text: 'Toutes les demandes', value: 'all' },
    { key: 2, text: 'Demandes entrantes', value: 'in' },
    { key: 3, text: 'Demandes sortantes', value: 'out' },
    { key: 4, text: 'Demandes acceptées', value: 'accepted' },
    { key: 5, text: 'Demandes validées', value: 'validated' },
    { key: 6, text: 'Demandes rejetées', value: 'rejected' },
    { key: 7, text: 'Demandes en attente', value: 'pending' },
]

export function CashierApproPage() {


    
    const [approsList, setApprosList] = useState<Appro[]>([])
    const [approsListCopy, setApprosListCopy] = useState<Appro[]>([])
    const [loadingApprosList, setLoadingApprosList] = useState(true)
    const [actionError, setActionError] = useState(false)
    const [errorFetchingApprosList, setErrorFetchingApprosList] = useState(false)

    const [selectedAppro, setSelectedAppro] = useState<Appro>()
    const [selectedApproOrder, setSelectedApproOrder] = useState<number>()
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [acceptingRequest, setAcceptingRequest] = useState(false)
    const [rejectingRequest, setRejectingRequest] = useState(false)
    const [confirmingRequest, setConfirmingRequest] = useState(false)


    useEffect(() => {
        getApproList()
    }, [])


    const addToList = (appro: Appro) => setApprosList([appro, ...approsList])

    const handleApproSelection = (appro: Appro, idx: number) => {

        console.log(appro)

        setSelectedAppro(appro)

        setSelectedApproOrder(idx + 1)
    }

    const getApproList = async () => {

        try {
            const result = await CashierService.getRequestsForAppro()

            setApprosList(result)

            setApprosListCopy(result)

            setLoadingApprosList(false)

        } catch (error) {

            setErrorFetchingApprosList(true)

            setLoadingApprosList(false)
        }
    }

    const rejectRequestForAppro = () => {
        setRejectingRequest(true)

        CashierService.rejectRequestForAppro(selectedAppro?.id!)
            .then(data => {
                const updated = approsList.map(appro => {
                    if (appro.id === data.id) {
                        return data
                    }
                    return appro
                })
                setApprosList(updated)
                setSelectedAppro(data)
            })
            .catch(_ => setActionError(true))
            .finally(() => setRejectingRequest(false))
    }

    const acceptRequestForAppro = () => {
        setAcceptingRequest(true)

        CashierService.acceptRequestForAppro(selectedAppro?.id!)
            .then(data => {
                const updated = approsList.map(appro => {
                    if (appro.id === data.id) {
                        return data
                    }
                    return appro
                })
                setApprosList(updated)
                setSelectedAppro(data)
            })
            .catch(_ => setActionError(true))
            .finally(() => setAcceptingRequest(false))
    }

    const confirmRequestAppro = () => {

        setConfirmingRequest(true)

        CashierService.confirmRequestAppro(selectedAppro?.id!)
            .then(data => {
                const updated = approsList.map(appro => {
                    if (appro.id === data.id) {
                        return data
                    }
                    return appro
                })
                setApprosList(updated)
                setSelectedAppro(data)
            })
            .catch(_ => setActionError(true))
            .finally(() => setConfirmingRequest(false))
    }

    const handleFilterChange = (e: any, data: any) => {

        const opt = data.value

        if (opt === 'all') {
            setApprosList(approsListCopy)
            return
        }

        if (opt === 'in') {
            const inList = approsListCopy.filter(a => a.recipientPhone === HelperService.getPhoneFromToken())
            setApprosList(inList)
            return
        }

        if (opt === 'out') {
            const outList = approsListCopy.filter(a => a.initiatorPhone === HelperService.getPhoneFromToken())
            setApprosList(outList)
            return
        }

        if (opt === 'accepted') {
            const acceptedList = approsListCopy.filter(a => a.status === 'accepted')
            setApprosList(acceptedList)
            return
        }

        if (opt === 'validated') {
            const validatedList = approsListCopy.filter(a => a.status === 'confirmed')
            setApprosList(validatedList)
            return
        }

        if (opt === 'rejected') {
            const rejectedList = approsListCopy.filter(a => a.status === 'rejected')
            setApprosList(rejectedList)
            return
        }

        if (opt === 'pending') {
            const pendingList = approsListCopy.filter(a => a.status === 'pending')
            setApprosList(pendingList)
            return
        }

    }

    const handleSearch = (e: any) => {
        const value = e.target.value.toLowerCase()

        if (value === '') {
            setApprosList(approsListCopy)
            return
        }

        const searchResult = approsListCopy.filter(a => a.reference?.toLowerCase().includes(value) ||
            a.amount?.toString().toLowerCase().includes(value) ||
            HelperService.formatDate(a.createdAt!).toLowerCase().includes(value)
        )

        setApprosList(searchResult)
    }

    return <section>
        <div className='flex space-between vertical-center '>
            <h2 className='mb-0'>Mes demandes d'appros   <Label circular color='orange' size='big'>{approsList.length}</Label> </h2>
            <Button color="black" onClick={() => setCreateModalVisible(true)}><Icon name="add" /> Demander un appro</Button>
        </div>
        <br />
        <div className={css.wrapper}>
            <div className={css.left}>
                <div className={css.header}>
                    <Input placeholder='Rechercher par transactions, montant ou date' onChange={handleSearch} className={css.searchbar} icon={{ name: 'search', circular: true, link: true }} />
                    <Dropdown inline options={filterOptions} defaultValue={filterOptions[0].value} className={css.dropdown} onChange={handleFilterChange} />
                </div>

                {loadingApprosList && <LoadingSpinner />}

                {errorFetchingApprosList && <ErrorMessage errorText={FeedbackMessage.APPRO_REQUEST_FETCH_ERROR} />}

                {loadingApprosList === false && !errorFetchingApprosList && approsList.length === 0 && <NoData>Pas de transactions</NoData>}

                {
                    !errorFetchingApprosList && approsList.length > 0 &&

                    <Table>
                        <TableHeader>
                            <TableRow className='cursor-pointer'>
                                <TableHeaderCell className='size-17'>Transactions</TableHeaderCell>
                                <TableHeaderCell className='size-17'>Montant</TableHeaderCell>
                                <TableHeaderCell className='size-17'>Date</TableHeaderCell>
                                <TableHeaderCell className='size-17'>Statut</TableHeaderCell>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            {
                                approsList.map((a, idx) =>

                                    <TableRow className='cursor-pointer' key={a.id} onClick={() => handleApproSelection(a, idx)}>
                                        <TableCell>
                                            <div className='flex-center-v'>
                                                <div className='mr-10'><img src={a.recipientPhone === HelperService.getPhoneFromToken() ? '/images/emballage.png' : '/images/deballage3.png'} alt="logo" className='icon-wrapper' width={40} /></div>
                                                <div>

                                                    <strong className='size-17'>{a.recipientPhone === HelperService.getPhoneFromToken() ? 'Demande reçue' : 'Demande envoyée'} #{idx + 1}</strong><br />
                                                    <small>Ref: {a.reference}</small>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>{HelperService.formatAmount(a.amount)}</TableCell>
                                        <TableCell>{HelperService.formatDate(a?.createdAt!)}</TableCell>
                                        <TableCell><Badge type={a.status!}>{a.statusText}</Badge></TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                }

            </div>

            {
                <div className={selectedAppro ? css.right + ' ' + css.resized : css.right}>
                    {actionError && <div className='p-5'><ErrorMessage errorText={FeedbackMessage.APPRO_REQUEST_ACTION_ERROR} /></div>}
              
                        <div className='p-20'>
                            <div className='flex vertical-center space-between mb-20'>
                                <div className='flex vertical-center'>
                                    <img src={selectedAppro?.recipientPhone === HelperService.getPhoneFromToken() ? '/images/emballage.png' : '/images/deballage3.png'} alt="logo" className='icon-wrapper mr-10' width={45} />
                                    <strong className='size-17'> {selectedAppro?.recipientPhone === HelperService.getPhoneFromToken() ? 'Demande reçue' : 'Demande envoyée'} #{selectedApproOrder}</strong>
                                </div>
                                <Badge type={selectedAppro?.status!}>{selectedAppro?.statusText}</Badge>
                            </div>


                            <h3>Détails de la transaction</h3>
                            <Divider />
                            <br />
                            <div className='flex space-between mb-10'>
                                {selectedAppro?.recipientPhone === HelperService.getPhoneFromToken() ? <span>Demandeur:</span> : <span>Destinataire:</span>}
                                <span>
                                    {selectedAppro?.recipientType}
                                    ({selectedAppro?.recipientPhone === HelperService.getPhoneFromToken() ? selectedAppro?.initiatorName : selectedAppro?.recipientFullName})
                                </span>
                            </div>
                            <div className='flex space-between mb-10'>
                                <span>Montant de transaction:</span>
                                <span>{HelperService.formatAmount(selectedAppro?.amount)} Fcfa</span>
                            </div>
                            <div className='flex space-between mb-10'>
                                <span>Numéro de transaction:</span>
                                <span>{selectedAppro?.reference}</span>
                            </div>
                            <div className='flex space-between mb-20'>
                                <span>Date de la transaction:</span>
                                <span>{HelperService.formatDateTime(selectedAppro?.createdAt)}</span>
                            </div>
                            <div className='flex space-between'>
                                <span>Type d'approvisionnement:</span>
                                <span><img src={HelperService.getLogoImageUrl(selectedAppro?.moneyType!)} alt="logo" width={50} className='radius-6' /></span>
                            </div>


                            <h3>Chronologie</h3>
                            <Divider />
                            <List as='ul'>
                                <ListItem as='li' className='saumon text-bold'>{HelperService.formatDate(selectedAppro?.createdAt)}</ListItem>
                                <p className='text-bold size-16 mt-5 mb-2'>Demande</p>
                                <p>Approvisionnement demandé</p>
                                <br />

                                {
                                    ['accepted', 'confirmed'].includes(selectedAppro?.status!) &&
                                    <>
                                        <ListItem as='li' className='saumon text-bold'>{HelperService.formatDate(selectedAppro?.acceptedByRecipientAt)}</ListItem>
                                        <p className='text-bold size-16 mt-5 mb-2'>Traitement</p>
                                        <p>Acceptation de la demande</p>
                                        <br />
                                    </>
                                }
                                {selectedAppro?.status === 'rejected' &&
                                    <>
                                        <ListItem as='li' className='saumon text-bold'>{HelperService.formatDate(selectedAppro.rejectedByRecipientAt)}</ListItem>
                                        <p className='text-bold size-16 mt-5 mb-2'>Traitement</p>
                                        <p>Rejet de la demande</p>
                                        <br />
                                    </>
                                }
                                {
                                    selectedAppro?.status === 'confirmed' &&
                                    <>
                                        <ListItem as='li' className='saumon text-bold'>{HelperService.formatDate(selectedAppro.confirmedByCashierAt)}</ListItem>
                                        <p className='text-bold size-16 mt-5 mb-2'>Confirmation</p>
                                        <p>Fonds reçus par le demandeur</p>
                                        <br />
                                    </>
                                }

                            </List>
                            {
                                selectedAppro?.recipientPhone === HelperService.getPhoneFromToken() && selectedAppro?.status === 'pending' &&
                                <>
                                    <br />
                                    <h3>Actions</h3>
                                    <Divider />
                                    <br />
                                    <Button color="orange" className='mr-25' onClick={rejectRequestForAppro} loading={rejectingRequest}><Icon name="ban" /> Rejeter</Button>
                                    <Button color="black" onClick={acceptRequestForAppro} loading={acceptingRequest}><Icon name="check" /> Confirmer</Button>
                                </>
                            }

                            {
                                selectedAppro?.initiatorPhone === HelperService.getPhoneFromToken() && selectedAppro?.status === 'accepted' &&
                                <>
                                    <br />
                                    <h3>Actions</h3>
                                    <Divider />
                                    <br />
                                    <Button color="black" onClick={confirmRequestAppro} loading={confirmingRequest}><Icon name="check" /> Confirmer la réception de l'argent</Button>
                                </>
                            }
                        </div>
                </div>
            }
        </div>

        {createModalVisible && <CashierApproCreateModalForm setCreateModalVisible={setCreateModalVisible} addApproToList={addToList} />}
    </section>
}
