import { useEffect, useState } from 'react'
import { Form, Button, FormField, FormGroup } from 'semantic-ui-react'

import { ErrorMessage } from './error-message'
import { FeedbackMessage, DropdownOptions } from '../constants'
import { HelperService, TillService, DsitributorService } from '../services'
import { Appro } from '../models'


const inlineStyle = {
    wrapper: {
        padding: '30px',
        borderRadius: '5px',
        backgroundColor: '#f9fafb',
        minHeight: '220px',
        border: '1px solid #d4d4d5',
    },
    select: {
        lineHeight: '22px'
    },
    input: {
        lineHeight: '25px'
    }
}


export function DistributorApproForm({ addToList, hideForm }: { addToList: Function, hideForm: Function }) {

    const [error, setError] = useState(false)
    const [tills, setTills] = useState<any[]>([])
    const [tillId, setTillId] = useState<number>()
    const [amount, setAmount] = useState<string>('')
    const [approType, setApproType] = useState('cash')
    const [creating, setCreating] = useState(false)
    const [userRole, setUserRole] = useState<string>('')

    useEffect(() => {
        setUserRole(HelperService.getRoleFromToken())

        TillService.getTills()
            .then(data => setTills(HelperService.formatTillForDropdown(data)))
            .catch(_ => setError(true))
    }, [])


    const handleFormSubmit = () => {

        if (!tillId)
            return alert('Veuillez choisir la caisse à approvisionner')

        if (!amount)
            return alert('Veuillez renseigner le montant de l\'approvisionnement')

        if(!HelperService.isInteger(amount) || Number(amount) <= 0)
            return alert('Le montant doit être un nombre supérieur à zéro')


        setCreating(true)

        const appro = new Appro({ amount: Number(amount), approType, tillId })

        DsitributorService.createAppro(appro)
            .then(data => {
                addToList(data)
                setTillId(undefined)
                setAmount('')
                hideForm()
            })
            .catch(_ => setError(true))
            .finally(() => setCreating(false))
    }

    const handleTypeChange = (_: any, data: any) => setApproType(data.value)

    const handleTillChange = (_: any, data: any) => setTillId(data.value)

    return <div style={inlineStyle.wrapper}>

        {error && <ErrorMessage errorText={FeedbackMessage.APPRO_CREATE_ERROR} />}

        <Form>
            <br />
            <FormGroup widths='equal'>
                <FormField>
                    <label><h3>Caisse</h3></label>
                    <Form.Select options={tills} value={tillId} onChange={handleTillChange} placeholder='Caisse à approvisionner' style={inlineStyle.select} />
                </FormField>

                <FormField>
                    <label><h3>Montant</h3></label>
                    <input type='text' value={amount} onChange={e => setAmount(e.target.value)} placeholder="Montant de l'approvisionnement en F CFA" style={inlineStyle.input} />
                </FormField>

                <FormField>
                    <label><h3>Type</h3></label>
                    <Form.Select options={DropdownOptions.moneyTypeOptions} value={approType} onChange={handleTypeChange} placeholder='Type des fonds' style={inlineStyle.select} />
                </FormField>

            </FormGroup>

            <br />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className='size-16' color='orange' content="Annuler" onClick={() => hideForm()} icon='trash alternate' />
                &nbsp;&nbsp;&nbsp;
                <Button className='size-16' color='black' content="Confirmer" onClick={handleFormSubmit} icon='check' loading={creating} />
            </div>
        </Form>
    </div>
}