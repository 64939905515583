import { ChangeEvent } from 'react'

import { jwtDecode } from "jwt-decode"

import { Agency, Area, Company, Till } from "../models"

export class HelperService {

    static genStrongPassword = (): string => {

        let dico = '@#$ABCDEFGHI@#$ABCDEFGHIJKLMNOPQRS@#$TUVWXYZ123456@#$ABCDEFGHIJKLMNOPQRS@#$TUVWXYZ123456789abcdefghijklmnopqrstuvwxyz@#$789abcdefghijklmnopqrstuvwxyz@#$JKLMNOPQRS@#$TUVWXYZ123456789abcdefghijklmnopqrstuvwxyz@#$'

        dico = dico.repeat(10)


        return this.shuffleStr(dico).slice(17, 25)
    }

    static shuffleStr = (str: string) => {
        return str.split('')
            .sort(_ => Math.random() - 0.5)
            .sort(_ => Math.random() - 0.5)
            .sort(_ => Math.random() - 0.5)
            .sort(_ => Math.random() - 0.5)
            .join('')
    }

    static getCompanyName = (): string => {

        let name = localStorage.getItem('okdev__companyName')

        if(name)
            return name

        const urlParts = window.location.href.split('/')

        name = urlParts[urlParts.length - 2]

        localStorage.setItem('okdev__companyName', name)

        return name
    }

    static getCurrentPath = (): string => {

        const urlParts = window.location.href.split('/')

        const path = urlParts[urlParts.length - 1]

        console.log('path', path)

        return path
    }

    static getRoleFromScopes = (permissions: string[]): string => {

        const permissionNames = permissions.map((p: any) => p.name)

            if(permissionNames.includes('admin_sys'))
                return 'Admin'

            if(permissionNames.includes('company_supervisor'))
                return 'Superviseur'

            if(permissionNames.includes('company_general_distributor'))
                return 'Dist General'

            if(permissionNames.includes('company_local_distributor'))
               return 'Dist Local'

            if(permissionNames.includes('company_cashier'))
                return 'Caissier'
            
            return ''
    }


    static getFile = (e: ChangeEvent<HTMLInputElement>): File | null => {

        if (!e.target.files || e.target.files.length === 0) return null

        const acceptedTypes = ['application/pdf', 'image/png', 'image/jpeg']

        const selectedFile = e.target.files[0]

        if (!acceptedTypes.includes(selectedFile.type)) return null

        return selectedFile
    }

    
    static storeAccessToken = (accessToken: string) => localStorage.setItem('okdev_xth_token', accessToken)

    static storeAreas = (data: any) => localStorage.setItem('okdev_areas', JSON.stringify(data))

    static retrieveAreas = (): Area[] => JSON.parse(localStorage.getItem('okdev_areas') || '[]')

    static storeCompanies = (data: Company[]) => localStorage.setItem('okdev_companies', JSON.stringify(data))

    static retrieveCompanies = (): Company[] => JSON.parse(localStorage.getItem('okdev_companies') || '[]')

    static storeAgencies = (data: any) => localStorage.setItem('okdev_agencies', JSON.stringify(data))

    static retrieveAgencies = (): Agency[] => JSON.parse(localStorage.getItem('okdev_agencies') || '[]')

    static clearStorage = () => {

        localStorage.removeItem('okdev_xth_token')

        localStorage.removeItem('okdev_areas')

        localStorage.removeItem('okdev__companyName')

        localStorage.removeItem('okdev_companies')

        localStorage.removeItem('okdev_agencies')
    }

    static getRoleFromToken = (forAdmin: boolean = false): string => {

        const token = localStorage.getItem('okdev_xth_token') 

        if(!token) return ''

        const decoded = jwtDecode(token) as any
        
        if(decoded.scopes.includes('admin_sys'))
            return 'Admin'

        if(decoded.scopes.includes('company_supervisor'))
            return 'Superviseur'

        if(decoded.scopes.includes('company_general_distributor'))
            return forAdmin ?  'Dist General' : 'Distributeur'

        if(decoded.scopes.includes('company_local_distributor'))
           return forAdmin ? 'Dist Local' : 'Distributeur'

        if(decoded.scopes.includes('company_cashier'))
            return 'Caissier'
        
        return ''
    }

    static getFullNameFromToken = (): string => {
        
        const token= localStorage.getItem('okdev_xth_token') 

        if(!token) return ''

        const decoded = jwtDecode(token) as any

        console.log('decoded', decoded)

        return decoded.firstname + ' ' + decoded.lastname
    }

    static getPhoneFromToken = (): string => {
        
        const token= localStorage.getItem('okdev_xth_token') 

        if(!token) return ''

        const decoded = jwtDecode(token) as any

        return decoded.sub
    }

    static formatAmount = (amount: number | undefined): string =>  amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : ''

    static formatPhoneNumber = (phone: string): string =>  phone.slice(0, 2) + ' ' + phone.slice(2, 5) + ' ' + phone.slice(5,7) + ' ' + phone.slice(7) 

    static formatDateTime = (d?: Date): string => {

        if(d === null || d === undefined) return ''

        return d.toLocaleDateString('fr', { year: "numeric", month: "short", day: "numeric" }) + ', ' + d.toLocaleTimeString('fr', { hour: '2-digit', minute: '2-digit' })
    }

    static formatDate = (d?: Date | string): string => {

        if(d === null || d === undefined) return ''

        return new Date(d).toLocaleDateString('fr', { year: "numeric", month: "long", day: "numeric" }) 
    }

    static formatTillForDropdown = (tills: Till[]): any[] => tills.map((till: any) => ({ key: till.id, text: 'Caisse ' +  till.id + ': (Agence ' + till.agency?.name + ' - ' + till.user?.firstname + ' ' + till.user?.lastname + ')', value: till.id }))

    static isInteger = (value: string): boolean => {

        const digits = '0123456789'

        return value.split('').every(c => digits.includes(c))
    }


    static getBalanceByName = (balances: any[], name: string): any => {
            
            const balance = balances.find((b: any) => b.money_type === name)

            return balance 
    }

    static computeTotalBalance = (balances: any[]): number => balances.reduce((acc: number, b: any) => acc + b.amount, 0)


    static getAnterorityInDays = (d: Date | string | undefined | null): string => {

            if(d == null || d === undefined) return ''
            
            d = new Date(d)

            const now = new Date()
    
            const diff = now.getTime() - d.getTime()
    
            return Math.floor(diff / (1000 * 60 * 60 * 24)) + ' jours'
    }

    static padNumberLeft = (num: number, size: number): string => {

        let s = num + ""

        while (s.length < size) s = "0" + s

        return s
    }

    static buildDistLineChartDataset = (data: any[]): any => data.map(d => ({name : new Date(d.the_day).toLocaleDateString('fr', { year: "numeric", month: "numeric", day: "numeric" }), appro: d.the_value}))

    static getLogoImageUrl = (mobileMoneyName: string): string => {

        const mapping: {[k: string]: string} = {
            'ria': 'ria.png',
            'cash': 'cfa.png',
            'kpay': 'kpay.png',
            'wave': 'wave.jpg',
            'kash-kash': 'kash-kash.png',
            'moneygram': 'moneygram.png',
            'free-money': 'freemoney.png',
            'small-world': 'smallworld.png',
            'orange-money': 'orangemoney.png',
            'western-union': 'western-union.png',
        }

        if(!mobileMoneyName) return ''

        return '/images/' + mapping[mobileMoneyName]
    }

    static guessApproTreatmentDate = (acceptionDate: Date | string, rejectionDate: Date | string): string => {
        return "TODO"
    }

}