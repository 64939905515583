import { useEffect, useState } from 'react'

import { Button, Form, Header, Modal } from 'semantic-ui-react'

import { DropdownOptions, FeedbackMessage } from '../constants'

import { ErrorMessage } from './error-message'

import { CashierService } from '../services'

import { HelperService } from '../services'

const css = {

    step0: {
        height: '230px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '100px',
        paddingRight: '100px'
    },

    step2: {
        height: '230px',
        display: 'flex',
        "flex-direction": 'column',
        alignItems: 'center',
        paddingLeft: '100px',
        paddingRight: '100px'
    },

    imgWrapper: {
        width: '130px',
        height: '130px',
        borderRadius: '8px',
        display: 'flex',
        "flex-direction": 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f3f3f3',
        cursor: 'pointer'
    },

    selectedImgWrapper: {
        width: '130px',
        height: '130px',
        borderRadius: '8px',
        display: 'flex',
        "flex-direction": 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f3f3f3',
        cursor: 'pointer',
        border: '2px solid #f2711c'
    }

}

export function CashierApproCreateModalForm({ setCreateModalVisible, addApproToList }: { setCreateModalVisible: Function, addApproToList: Function }) {

    const [amount, setAmount] = useState<string>('')
    const [recipientType, setRecipientType] = useState<string>('')
    const [moneyType, setMoneyType] = useState<{ id: number, name: string }>()
    const [recipient, setRecipient] = useState<{ id: number, name: string }>()
    const [cashiersList, setCashiersList] = useState<any[]>([])
    const [distributorsList, setDistributorsList] = useState<any[]>([])
    const [creatingAppro, setCreatingAppro] = useState(false)
    const [approCreationError, setApproCreationError] = useState(false)
    const [stepValue, setStepValue] = useState(0)

    useEffect(() => {
        getCashiersList()
        getDistributorsList()
    }, [])

    const handleFormSubmit = () => {

        const payload: any = {}

        payload.amount = amount
        payload.money_type = moneyType?.id

        if (recipientType === 'dist')
            payload.distributor_id = recipient?.id
        else
            payload.recipient_till_id = recipient?.id

        setCreatingAppro(true)

        CashierService.sendRequestForAppro(payload)
        .then(a => {
            addApproToList(a)
            setCreateModalVisible(false)
        }).catch(_ => setApproCreationError(true))
        .finally(() => setCreatingAppro(false))
    }

    const handleNext = () => {

        if (stepValue === 0 && recipientType.length) {
            setStepValue(1)
        }

        if (stepValue === 1 && recipient && moneyType && amount.toString().length > 0) {
            setStepValue(2)
        }

    }


    const getDistributorsList = async () => {
        try {
            const result = await CashierService.getDistributorsList()
            setDistributorsList(result)
        } catch (error) {
            setApproCreationError(true)
        }
    }


    const getCashiersList = async () => {
        try {
            const result = await CashierService.getCashiersList()
            setCashiersList(result)
        } catch (error) {
            setApproCreationError(true)
        }
    }

   

    return <Modal
        size='small'
        onClose={() => setCreateModalVisible(false)}
        onOpen={() => setCreateModalVisible(true)}
        open={true}>
        <Modal.Content >
            <Modal.Description>
                <Header as="h3">Demande d'approvisionnement</Header>
                {approCreationError && <ErrorMessage errorText={FeedbackMessage.APPRO_REQUEST_CREATE_ERROR} />}
                <br />
                {
                    stepValue === 0 &&
                    <div style={css.step0}>

                        <div style={recipientType === 'dist' ? css.selectedImgWrapper : css.imgWrapper} onClick={() => setRecipientType('dist')}>
                            <img src="/images/dist-avatar.png" alt="avatar" width={70} />
                            <p className='mt-15'>Distributeur</p>
                        </div>

                        <div style={recipientType === 'cashier' ? css.selectedImgWrapper : css.imgWrapper} onClick={() => setRecipientType('cashier')}>
                            <img src="/images/user-avatar.png" alt="avatar" width={70} />
                            <p className='mt-15'>Caissier</p>
                        </div>
                    </div>
                }
                {
                    stepValue === 1 &&
                    <div>
                        <Form>
                            {
                                recipientType === 'dist' &&
                                <Form.Select
                                    fluid
                                    label='Distributeur'
                                    options={distributorsList.map(d => ({ key: d.id, text: d.firstname + ' ' + d.lastname, value: d.id }))}
                                    placeholder='Choisir le distributeur'
                                    value={recipient?.id}
                                    onChange={(e: any, data: any) => setRecipient({ id: data.value, name: e.target.innerText })}
                                />
                            }
                            {
                                recipientType === 'cashier' &&
                                <Form.Select
                                    fluid
                                    label='Caissier'
                                    options={cashiersList.map(c => ({ key: c.id, text: c.user_fullname, value: c.id }))}
                                    placeholder='Choisir le caissier'
                                    value={recipient?.id}
                                    onChange={(e: any, data: any) => setRecipient({ id: data.value, name: e.target.innerText })}
                                />
                            }

                            <br />

                            <Form.Select
                                fluid
                                label='Nature des fonds'
                                options={DropdownOptions.moneyTypeOptions}
                                placeholder="Choisir la nature des fonds"
                                value={moneyType?.id}
                                onChange={(e: any, data: any) => setMoneyType({ id: data.value, name: e.target.innerText })}
                            />

                            <br />
                            <Form.Input label='Montant' placeholder='Montant demandé' value={amount} onChange={e => setAmount(e.target.value)} />
                        </Form>
                    </div>
                }
                {
                    stepValue === 2 &&
                    <div style={css.step2}>
                        <h3>Confirmer la demande d'approvisionnement</h3>
                        <br />

                        <p className='mb-15'><strong>Montant</strong>: {HelperService.formatAmount(Number(amount))} Fcfa</p>
                        <p className='mb-15'><strong>Nature des fonds</strong>: {moneyType?.name}</p>
                        <p><strong>Destinataire</strong>: {recipient?.name} - {recipientType === 'dist' ? 'Distributeur' : 'Caissier'}</p>
                    </div>
                }
                <br />
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setCreateModalVisible(false)}>Annuler</Button>
            {stepValue < 2 && <Button color='orange' onClick={handleNext} loading={creatingAppro}>Suivant</Button>}
            {stepValue === 2 && <Button color='orange' onClick={handleFormSubmit} loading={creatingAppro}>Confirmer</Button>}
        </Modal.Actions>
    </Modal>
}