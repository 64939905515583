import { useState } from "react"

import { Outlet, useNavigate } from "react-router-dom"

import { Icon, Menu, MenuItem, MenuMenu } from "semantic-ui-react"

import { menuMappping } from "../../constants"

import { HelperService } from '../../services'


export function CashierLayoutPage() {

    const navigate = useNavigate()
    const [companyName] = useState(HelperService.getCompanyName())
    const [activeItem, setActiveItem] = useState(menuMappping[HelperService.getCurrentPath()])


    const handleItemClick = (e: any, { name }: any) => {
        
        setActiveItem(menuMappping[name.toLowerCase()])
        
        navigate(`/${companyName}/cashier/${menuMappping[name.toLowerCase()]}`)
    }

    return <>
        <div className="container">

            <Menu secondary color='orange' size='large'>
                <MenuItem
                    name='Accueil'
                    active={activeItem === ''}
                    onClick={handleItemClick}
                    icon='home'
                    color='orange'
                />

                <MenuItem
                    name='Mes demandes'
                    active={activeItem === 'request-for-appros'}
                    onClick={handleItemClick}
                    key='request-for-appros'
                    icon='exchange'
                    color='orange'
                />       

                <MenuItem
                    name='Historique'
                    active={activeItem === 'provisionning'}
                    onClick={handleItemClick}
                    key='appros'
                    icon='wait'
                    color='orange'
                />            
                <MenuItem
                    name='Ma caisse'
                    active={activeItem === 'macaisse'}
                    onClick={handleItemClick}
                    key='caisse'
                    icon='cube'
                    color='orange'
                />

                <MenuMenu position='right'>
                    <MenuItem
                        name='profile'
                        active={activeItem === 'profile'}
                        onClick={handleItemClick}>
                        <Icon name='user circle outline' size="large"/>Mon compte
                    </MenuItem>
                </MenuMenu>
            </Menu>
            <br />
            <Outlet />
        </div>
    </>
}