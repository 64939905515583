import { Loader } from 'semantic-ui-react'

import { HelperService } from "../services"

import css from './dash-card.module.css'

export function DashCard({title, subTitle, balance, imageUrl, imgHeight, imgWidth, loading}: {title: string, subTitle: string | number, balance: number | string, imageUrl: string, imgHeight?: number, imgWidth?: number, loading?: boolean}) {
    
    if(!imgHeight) imgHeight = 55
    if(!imgWidth) imgWidth = 55

    return <div className={css.dashCard}>
                <img src={imageUrl} alt="" width={imgWidth} />
                <div>
                    <p className={css.dashCardHeader}>
                        <span>{title}</span>
                        {!loading &&<span>{subTitle}</span>}
                        {loading &&<span><Loader size='mini' active inline /></span>}
                    </p>
                    {!loading && <h4 style={{marginTop: '12px'}}>{HelperService.formatAmount(Number(balance))} Fcfa</h4>}
                    {loading &&  <h4 className={css.loaderWrapper}><Loader size='mini' active inline /></h4>}
                </div>
                
            </div>

}