import { getAxiosInstance } from "../http";

export class DashboardService {

    static getDistributorBalances = async() => {

        const instance = getAxiosInstance()
        
        const response = await instance.get('distributors/balances')

        return response.data
    }

    static getDistributorApproStats = async() => {

        const instance = getAxiosInstance()

        const response = await instance.get('distributors/appros_status_stats')

        return response.data
    }

    static getDistributorApproPerDay = async() => {

        const instance = getAxiosInstance()

        const response = await instance.get('distributors/appros_per_day')

        return response.data
    }
}
