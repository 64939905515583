export class Appro {

    public id?: number;
    public reference?: string;
    public initiatorId?: number;
    public initiatorName?: string;
    public initiatorPhone?: string;
    public initiatorTillId?: number;
    public initiatorPermission?: string;
    public recipientFullName?: string;
    public recipientPhone?: string;
    public recipientTillIdOrDistributorId?: number;
    public recipientType?: string;
    public tillId?: number;
    public cashierName?: string;
    public amount?: number;
    public status?: string;
    public statusText?: string;
    public companyId?: number;
    public companyName?: string;
    public moneyType?: string;
    public comments?: string;
    public createdAt?: Date;
    public updatedAt?: Date;
    public confirmedByCashierAt?: Date;
    public acceptedByRecipientAt?: Date;
    public rejectedByRecipientAt?: Date;
    public validatedByCashierAt?: Date;


    constructor(args: {id?: number, reference?: string, initiatorId?: number, initiatorName?: string, initiatorPermission?: string, tillId?: number, cashierName?: string, amount?: number, createdAt?: Date, updatedAt?: Date, validatedByCashierAt?: Date, status?: string, statusText?: string, companyId?: number, 
        companyName?: string, approType?: string, comments?: string, initiatorPhone?: string, initiatorTillId?: number, recipientFullName?: string, recipientPhone?: string, recipientTillIdOrDistributorId?: number, recipientType?: string, moneyType?: string, confirmedByCashierAt?: Date, acceptedByRecipientAt?: Date, rejectedByRecipientAt?: Date
    }) {
        this.id = args.id
        this.reference = args.reference

        this.initiatorId = args.initiatorId
        this.initiatorName = args.initiatorName
        this.initiatorPermission = args.initiatorPermission
        this.initiatorPhone = args.initiatorPhone
        this.initiatorTillId = args.initiatorTillId

        this.recipientFullName = args.recipientFullName
        this.recipientPhone = args.recipientPhone
        this.recipientTillIdOrDistributorId = args.recipientTillIdOrDistributorId
        this.recipientType = args.recipientType

        this.tillId = args.tillId
        this.cashierName = args.cashierName
        this.amount = args.amount
        this.validatedByCashierAt = args.validatedByCashierAt
        this.status = args.status
        this.statusText = args.statusText
        this.companyId = args.companyId
        this.companyName = args.companyName
        this.comments = args.comments

        this.moneyType = args.moneyType
        this.createdAt = args.createdAt
        this.updatedAt = args.updatedAt
        this.confirmedByCashierAt = args.confirmedByCashierAt
        this.acceptedByRecipientAt = args.acceptedByRecipientAt
        this.rejectedByRecipientAt = args.rejectedByRecipientAt
    }


    static fromJSON(jsonData: any): Appro {
        const statusMapping: any = {
            pending: 'En cours',
            rejected: 'Rejeté',
            accepted: 'Accepté',
            confirmed: 'Confirmé',
        }
        return new Appro({
            id: jsonData.id,
            reference: jsonData.transaction_id,
            initiatorId: jsonData.initiator_id,
            initiatorName: jsonData.initiator_fullname,
            initiatorPermission: jsonData.initiator_permission,
            tillId: jsonData.till_id,
            cashierName: jsonData.till_user_fullname,
            amount: jsonData.amount,
            createdAt: new Date(jsonData.created_at),
            updatedAt: new Date(jsonData.updated_at),
            status: jsonData.status,
            statusText: statusMapping[jsonData.status],
            companyId: jsonData.company_id,
            companyName: jsonData.company_name,
            comments: jsonData.comments,
            initiatorPhone: jsonData.initiator_phone,
            initiatorTillId: jsonData.initiator_till_id,
            recipientFullName: jsonData.recipient_fullname,
            recipientPhone: jsonData.recipient_phone,
            recipientTillIdOrDistributorId: jsonData.recipient_till_id_or_distributor_id,
            recipientType: jsonData.recipient_type === 'to-distributor' ? 'Distributeur' : 'Caissier',
            moneyType: jsonData.money_type,
            validatedByCashierAt: jsonData.validated_by_cashier_at ? new Date(jsonData.validated_by_cashier_at) : undefined,
            confirmedByCashierAt: jsonData.confirmed_by_cashier_at ? new Date(jsonData.confirmed_by_cashier_at) : undefined,
            acceptedByRecipientAt: jsonData.accepted_by_recipient_at ? new Date(jsonData.accepted_by_recipient_at) : undefined,
            rejectedByRecipientAt: jsonData.rejected_by_recipient_at ? new Date(jsonData.rejected_by_recipient_at) : undefined
        })
    }


    static toJSON(Appro: Appro): any {
        
        return JSON.stringify({
            till_id: Appro.tillId,
            amount: Appro.amount,
            money_type: Appro.moneyType,
        })
    }
}