import { Button, Divider, Dropdown, Icon, Input, List, ListItem, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react"
import { Appro } from "../models"
import { NoData } from "./no-data"
import { Badge } from "./badge"
import { DsitributorService, HelperService } from "../services"

import css from './distributor-appro-list.module.css'
import { useState } from "react"
import { ErrorMessage } from "./error-message"
import { FeedbackMessage } from "../constants/feedback-msg"

const filterOptions = [
    { key: 1, text: 'Toutes les demandes', value: 'all' },
    { key: 2, text: 'Demandes acceptées', value: 'accepted' },
    { key: 3, text: 'Demandes validées', value: 'validated' },
    { key: 4, text: 'Demandes rejetées', value: 'rejected' },
    { key: 5, text: 'Demandes en attente', value: 'pending' },
]


export function DistributorApproList({ approsList, setApprosList, approsListCopy, setApprosListCopy, userRole }: { approsList: Appro[], approsListCopy: Appro[], setApprosListCopy: Function, setApprosList: Function, userRole: string }) {

    const [actionError, setActionError] = useState<boolean>(false)
    const [selectedAppro, setSelectedAppro] = useState<Appro>()
    const [acceptingRequest, setAcceptingRequest] = useState(false)
    const [rejectingRequest, setRejectingRequest] = useState(false)
    const [selectedApproOrder, setSelectedApproOrder] = useState<number>()


    const handleApproSelection = (appro: Appro, idx: number) => {
        setSelectedAppro(appro)
        setSelectedApproOrder(idx + 1)
    }

    const rejectRequestForAppro = () => {
        setRejectingRequest(true)

        DsitributorService.rejectRequestForAppro(selectedAppro?.id!)
            .then(data => {
                const updatedAppros = approsList.map(appro => {
                    if (appro.id === data.id) {
                        return data
                    }
                    return appro
                })
                setApprosList(updatedAppros)
                setSelectedAppro(data)
            })
            .catch(_ => setActionError(true))
            .finally(() => setRejectingRequest(false))
    }

    const acceptRequestForAppro = () => {
        setAcceptingRequest(true)

        DsitributorService.acceptRequestForAppro(selectedAppro?.id!)
            .then(data => {
                const updatedAppros = approsList.map(appro => {
                    if (appro.id === data.id) {
                        return data
                    }
                    return appro
                })
                setApprosList(updatedAppros)
                setSelectedAppro(data)
            })
            .catch(_ => setActionError(true))
            .finally(() => setAcceptingRequest(false))
    }

    const handleFilterChange = (e: any, data: any) => {

        const opt = data.value

        if (opt === 'all') {
            setApprosList(approsListCopy)
            return
        }

        if (opt === 'in') {
            const inList = approsListCopy.filter(a => a.recipientPhone === HelperService.getPhoneFromToken())
            setApprosList(inList)
            return
        }

        if (opt === 'out') {
            const outList = approsListCopy.filter(a => a.initiatorPhone === HelperService.getPhoneFromToken())
            setApprosList(outList)
            return
        }

        if (opt === 'accepted') {
            const acceptedList = approsListCopy.filter(a => a.status === 'accepted')
            setApprosList(acceptedList)
            return
        }

        if (opt === 'validated') {
            const validatedList = approsListCopy.filter(a => a.status === 'confirmed')
            setApprosList(validatedList)
            return
        }

        if (opt === 'rejected') {
            const rejectedList = approsListCopy.filter(a => a.status === 'rejected')
            setApprosList(rejectedList)
            return
        }

        if (opt === 'pending') {
            const pendingList = approsListCopy.filter(a => a.status === 'pending')
            setApprosList(pendingList)
            return
        }
    }

    const handleSearch = (e: any) => {
        const value = e.target.value.toLowerCase()

        if (value === '') {
            setApprosList(approsListCopy)
            return
        }

        const searchResult = approsListCopy.filter(a => a.reference?.toLowerCase().includes(value) ||
            a.amount?.toString().toLowerCase().includes(value) ||
            HelperService.formatDate(a.createdAt!).toLowerCase().includes(value)
        )

        setApprosList(searchResult)
    }

    return <div>
            <div className={css.wrapper}>
                <div className={css.left}>
                    <div className={css.header}>
                        <Input placeholder='Rechercher par transactions, montant ou date' onChange={handleSearch} className={css.searchbar} icon={{ name: 'search', circular: true, link: true }} />
                        <Dropdown inline options={filterOptions} defaultValue={filterOptions[0].value} className={css.dropdown} onChange={handleFilterChange} />
                    </div>

                    {
                        approsList.length === 0 ? <NoData /> :
                        
                        <Table>
                            <TableHeader>
                                <TableRow style={{ fontSize: '15px' }} className='cursor-pointer'>
                                    <Table.HeaderCell>N° Référence</Table.HeaderCell>
                                    {userRole === 'Superviseur' && <TableHeaderCell className="size-17">Compagnie</TableHeaderCell>}
                                    <TableHeaderCell className="size-17">Demandeur</TableHeaderCell>
                                    <TableHeaderCell className="size-17">Montant</TableHeaderCell>
                                    <TableHeaderCell className="size-17">Type</TableHeaderCell>
                                    <TableHeaderCell className="size-17">Caisse</TableHeaderCell>
                                    <TableHeaderCell className="size-17">Date</TableHeaderCell>
                                    <TableHeaderCell className="size-17">Statut</TableHeaderCell>
                                </TableRow>
                            </TableHeader>

                            <TableBody>

                                {
                                    approsList.map((appro, idx) =>
                                        <TableRow style={{ fontSize: '15px' }} key={appro.id} onClick={() => handleApproSelection(appro, idx)} className='cursor-pointer'>
                                            <TableCell>
                                                <div className='flex-center-v'>
                                                    <div className='mr-10'><img src="/images/deballage3.png" alt="logo" className='icon-wrapper' width={40} /></div>
                                                    <div>
                                                        <strong className='size-17'>Demande #{idx + 1}</strong><br />
                                                        <small>Ref: {appro.reference}</small>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {userRole === 'Superviseur' && <TableCell>{appro.companyName}</TableCell>}
                                            <TableCell>{appro.initiatorName}</TableCell>
                                            <TableCell>{HelperService.formatAmount(appro.amount!)}</TableCell>
                                            <TableCell>{appro.moneyType!}</TableCell>
                                            <TableCell><strong>caisse {appro.initiatorTillId}</strong></TableCell>
                                            <TableCell>{HelperService.formatDate(appro.createdAt!)}</TableCell>
                                            <TableCell><Badge type={appro.status!}>{appro.statusText}</Badge></TableCell>
                                        </TableRow>)
                                }

                            </TableBody>
                        </Table>
                    }


                </div>
                {
                    <div className={selectedAppro ? css.right + ' ' + css.resized : css.right}>

                        {actionError && <div className='p-5'><ErrorMessage errorText={FeedbackMessage.APPRO_REQUEST_ACTION_ERROR} /></div>}
                        <div className='p-20'>
                            <div className='flex vertical-center space-between mb-20'>
                                <div className='flex vertical-center'>
                                    <img src="/images/deballage3.png" alt="logo" className='icon-wrapper mr-10' width={45} />
                                    <strong className='size-17'>Demande #{selectedApproOrder}</strong>
                                </div>
                                <Badge type={selectedAppro?.status!}>{selectedAppro?.statusText}</Badge>
                            </div>


                            <h3>Détails de la transaction</h3>
                            <Divider />
                            <br />
                            <div className='flex space-between mb-10'>
                                <span>Destinataire:</span>
                                <span>{selectedAppro?.recipientType} ({selectedAppro?.initiatorName})</span>
                            </div>
                            <div className='flex space-between mb-10'>
                                <span>Montant de transaction:</span>
                                <span>{HelperService.formatAmount(selectedAppro?.amount)} Fcfa</span>
                            </div>
                            <div className='flex space-between mb-10'>
                                <span>Numéro de transaction:</span>
                                <span>{selectedAppro?.reference}</span>
                            </div>
                            <div className='flex space-between mb-20'>
                                <span>Date de la transaction:</span>
                                <span>{HelperService.formatDateTime(selectedAppro?.createdAt)}</span>
                            </div>
                            <div className='flex space-between'>
                                <span>Type d'approvisionnement:</span>
                                <span><img src={HelperService.getLogoImageUrl(selectedAppro?.moneyType!)} alt="logo" width={50} className='radius-6' /></span>
                            </div>


                            <h3>Chronologie</h3>
                            <Divider />
                            <List as='ul'>
                                <ListItem as='li' className='saumon text-bold'>{HelperService.formatDate(selectedAppro?.createdAt)}</ListItem>
                                <p className='text-bold size-16 mt-5 mb-2'>Demande</p>
                                <p>Approvisionnement demandé</p>
                                <br />

                                {
                                    ['accepted', 'confirmed'].includes(selectedAppro?.status!) &&
                                    <>
                                        <ListItem as='li' className='saumon text-bold'>{HelperService.formatDate(selectedAppro?.acceptedByRecipientAt)}</ListItem>
                                        <p className='text-bold size-16 mt-5 mb-2'>Traitement</p>
                                        <p>Acceptation de la demande</p>
                                        <br />
                                    </>
                                }
                                {selectedAppro?.status === 'rejected' &&
                                    <>
                                        <ListItem as='li' className='saumon text-bold'>{HelperService.formatDate(selectedAppro.rejectedByRecipientAt)}</ListItem>
                                        <p className='text-bold size-16 mt-5 mb-2'>Traitement</p>
                                        <p>Rejet de la demande</p>
                                        <br />
                                    </>
                                }
                                {
                                    selectedAppro?.status === 'confirmed' &&
                                    <>
                                        <ListItem as='li' className='saumon text-bold'>{HelperService.formatDate(selectedAppro.confirmedByCashierAt)}</ListItem>
                                        <p className='text-bold size-16 mt-5 mb-2'>Confirmation</p>
                                        <p>Réception confirmée par le demandeur</p>
                                        <br />
                                    </>
                                }

                            </List>
                            {
                                !selectedAppro?.acceptedByRecipientAt && !selectedAppro?.rejectedByRecipientAt &&
                                <>
                                    <br />
                                    <h3>Actions</h3>
                                    <Divider />
                                    <br />
                                    <Button color="orange" className='mr-25' onClick={rejectRequestForAppro} loading={rejectingRequest}><Icon name="ban" /> Rejeter</Button>
                                    <Button color="black" onClick={acceptRequestForAppro} loading={acceptingRequest}><Icon name="check" /> Accepter</Button>
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
    </div>
}









